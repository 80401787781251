.bg-animation-stage {
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index:0;
    &.fixed{
        position: fixed;
    }
    .bg-animation-wrapper {
        position: absolute;
        left: calc(50% - 555px);
        top:0;
        height: 100%;
        width: 1110px;
    }
    // section partnerships
    &.partnerships {
        .gradient {
            position: relative;
            z-index: 22;
            height: 40%;
            opacity: .8;
            &.blue {
                background: rgba(4,4,18,1);
                background: -moz-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(4,4,18,1)), color-stop(47%, rgba(0,24,94,0.53)), color-stop(100%, rgba(0,24,94,0)));
                background: -webkit-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: -o-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: -ms-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: linear-gradient(to bottom, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#040412', endColorstr='#00185e', GradientType=0 );
            }

        }
        .circle{
            border:100px solid #2323FF;
            border-radius: 50%;
            position: absolute;
            opacity:0.48;
            &.firstStep {
                opacity: 1;
            }
            &.secondStep{
                opacity:0;
            }
            $c1:734px;
            $c2:650px;
            $c3:580px;
            $c4:468px;
            
            &.c1 {
                width: $c1;
                height: $c1;
                // max-width: 80vw;
                // max-height: 80vw;
                transform: translate(166px, 296px);
                z-index:3;
                &.firstStep {
                    top: calc(40% - #{$c1}/2);
                    left: calc(50% - #{$c1}/2);
                }
                &.secondStep {
                    top: calc(10% - #{$c1}/2);
                    left: calc(50% - #{$c1}/2);
                }
            }
            &.c2 {
                width: $c2;
                height: $c2;
                // max-width: 70.4vw;
                // max-height: 70.4vw;
                // top: 247px;
                // left: 387px;
                transform: translate(387px, 247px);
                z-index:4;
                &.firstStep {
                    top: calc(40% - #{$c2}/2);
                    left: calc(50% - #{$c2}/2);
                }
                &.secondStep {
                    top: calc(10% - #{$c2}/2);
                    left: calc(50% - #{$c2}/2);
                }
            }
            &.c3 {
                width: $c3;
                height: $c3;
                // max-width: 63.2vw;
                // max-height: 63.2vw;
                // left: 124px;
                // top: 164px;
                transform: translate(124px, 164px);
                z-index:5;
                &.firstStep {
                    top: calc(40% - #{$c3}/2);
                    left: calc(50% - #{$c3}/2);
                }
                &.secondStep {
                    top: calc(10% - #{$c3}/2);
                    left: calc(50% - #{$c3}/2);
                }
            }
            &.c4 {
                width: $c4;
                height: $c4;
                // max-width: 50.4vw;
                // max-height: 50.4vw;
                // left: 299px;
                // top: 244px;
                transform: translate(299px, 244px);
                z-index:5;
                &.firstStep {
                    top: calc(40% - #{$c4}/2);
                    left: calc(50% - #{$c4}/2);
                }
                &.secondStep {
                    top: calc(10% - #{$c4}/2);
                    left: calc(50% - #{$c4}/2);
                }

            }
            &.c2, &.c3, &.c4 {
                // display: none;
            }
        }
    }
}