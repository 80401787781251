$font-body: Helvetica, Helvetica Neue, Arial, sans-serif;
$font-title: 'Montserrat', Helvetica, Helvetica Neue, Arial, sans-serif;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-medium: 500;
$body-bg: #000000;
$bg-blue: #00185E;
$bg-grey: #262626;
$body-color: #292929;
$home-img-shadow: 0 82px 90px rgba(0, 0, 0, .3);
$primary: #292929;
$secondary: #b09b62;
$gray: #666;
$bg-gray: #fafafa;
$bg-gray-header:#e5e5e5;
$mobile-limit: 989px;
$desktop-limit: 990px;
$mobile-typo-limit: 767px;
$no-padding-limit: 1310px;
$black: #000000;
$orange: #BAC7C4;
$purple: #0C3372;
$blue: #17ACEC;
$border-gray: rgba(255,255,255,.7);
$bg-gray: #F0F2F4;
$white: #fff;
$green: #BAC7C4;
$transition-time: 0.4s;
$long-transition-time: 0.8s;

$text-color: $black;
$border-color: #959595;