@import-normalize;
@import "styles/_variables.scss";
@import url(https://fonts.googleapis.com/css?family=Montserrat);

body {
    font-family: $font-body;
    line-height: 1.4;
    background-color: #666666;
    &.myloading {
        overflow: hidden;
    }
}
a {
  color: $text-color;
  text-decoration: none;
}
h1 {
    color: $text-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-title;
}
p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}
.btn:hover {
  background: #666;
}
.link {
    color: #fff;
    text-decoration: "none";
}
.header,
.header h1 {
    color: #fff;
    text-align: center;
    padding: 10px;
}
#trigger {
    top: 30%;
    position: absolute;
}
.kud{
    h1{
        font-size: 58px;
        font-weight: normal !important;
        margin-bottom: 300px;
        color:#fff;
        @media (max-width: ($mobile-limit - 1)) {
            font-size: 40px;
        }
    }

}
main {
   
    section {        
        height: 100%;
        position: relative;
        &.high{
            min-height: 800px;
        }
        &.p300 {
            padding-top: 300px;
            padding-bottom: 300px;
        }
        &.pt300 {
            padding-top: 300px;

        }
        .wrapper {
        }
        // partnerships
        &.parterships-intro {
            padding-top: 300px;
            padding-bottom: 1000px;
            background: $bg-blue;
        }
        &.bg--grey {
            background: $bg-grey;
        }
        &.bg--primary {
            background: $primary;
        }
        &.bg--blue {
            background-color:$bg-blue;
        }
        &.bg--blue2 {
            background-color:#014972;
        }
        
        &.bg-animation--test {
            padding-top: 150px;
            background: transparent;
            height: 1000px;
            background: $primary
        }
        //
        &.h100 {
            min-height: 100vh;
        }
        &.text-anim {
            padding-top: 400px;
            //background-image: url(/assets/site/images/powder-4.jpg);
        }

    }
    .container {
        position: relative;
        z-index: 10;
        .bg--white {
            background-color: #fff;
        }
        .bg--white-50 {
            background-color: rgba(255,255,255,.5);
        }
    }

}

.bg-animation-stage {
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index:0;
    &.fixed{
        position: fixed;
    }
    .bg-animation-wrapper {
        position: absolute;
        left: calc(50% - 555px);
        top:0;
        height: 100%;
        width: 1110px;
    }
    // section partnerships
    &.partnerships {
        #gradient {
            position: relative;
            z-index: 22;
            height: 40%;
            opacity: .8;
            &.blue {
                background: rgba(4,4,18,1);
                background: -moz-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(4,4,18,1)), color-stop(47%, rgba(0,24,94,0.53)), color-stop(100%, rgba(0,24,94,0)));
                background: -webkit-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: -o-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: -ms-linear-gradient(top, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                background: linear-gradient(to bottom, rgba(4,4,18,1) 0%, rgba(0,24,94,0.53) 47%, rgba(0,24,94,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#040412', endColorstr='#00185e', GradientType=0 );
            }
        }
    }
    // section security
    &.security {
        .square {
            // do not remove or squares badly aligned... ?
            transform: rotate(45deg);
        }
    }   
}