header{
    width: 100%;
    position: absolute;
    z-index:10;
    nav{
        padding-top: 30px !important;
        padding-bottom: 20px !important;
        ul {
            padding-left: 0;
            margin-left: 0;
            list-style-type: none;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            li{
                width: 32%;
                margin-right: 1%;
                margin-bottom: 10px;
                background: rgba(255,255,255,.4);
                text-align: center;
                border-radius: 2px;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                &.active,
                &:hover {
                    background: rgba(255,255,255,.7);
                    a{
                        color: #3F3F3F;
                        text-decoration: none;
                    }
                }
                a{
                    font-size: 12px;
                    padding:13px 0 10px 0;
                    text-transform: uppercase;
                    display: block;
                }
            }
        }
    }
}
// ul {
//     display: flex;
//     justify-content: center;
//     list-style-type: none;
//     margin-left: 0;
//     padding-left: 0;
//     li {
//         margin-right: 10px;
//         margin-left: 10px;
//         a {
//             color: #fff;
//         }
//     }
// }