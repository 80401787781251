@import "../../../styles/_variables.scss";
// loading:
.loading {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    display: none;
    justify-content: center;
    overflow: hidden;
    p {
        font-family: "Oswald", Arial, sans-serif;
        color: $white;
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 0;
        font-family: $font-body;
    }
    .debug{
        position: absolute;
        z-index:400;
        top:20px;
        left: 20px;
        width: 170px;
        height: 200px;
        padding:6px;
        display: flex;
        align-items: flex-end;
        overflow: scroll;
        border:1px solid $border-gray;
        display:none;
        p {
            font-family: Arial, sans-serif;
            font-size: 14px !important;
            text-transform: none !important;
            text-align: left;
            line-height: 17px;
        }
    }
    &.open {
        .curtain {
            &.top,
            &.bottom {
                transform: translateY(0);
            }
            &.top {
                p {
                    span {
                        margin-bottom: 0;
                    }
                }
            }
            &.bottom {
                p {
                    span {
                        margin-top: 0;
                    }
                }
            }
        }
        .border-shape {
            width: 150px;
            height: 150px;
            transform: rotate(15deg);
            opacity: 1;
        }
    }
    .curtain {
        position: absolute;
        width: 100%;
        height: 50%;
        background: $black;
        margin: 0 auto;
        p {
            position: absolute;
            opacity: 1;
            overflow: hidden;
            font-size: 30px;
        }
        .line {
            position: absolute;
            width: 0;
            height: 1px;
            background: $white;
            opacity: 0;
        }
        &.top {
            transform-origin: 50% top;
            transform: translateY(-100%);
            z-index: 2;
            p {
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                span {
                    display: block;
                    margin-bottom: -40px;
                }
            }
            .line {
                bottom: -1px;
                left: 0;
            }
        }

        &.bottom {
            z-index: 1;
            bottom: 0;
            transform-origin: 50% bottom;
            transform: translateY(100%);
            p {
                padding-bottom: 6px;
                left: 50%;
                transform: translateX(-50%);
                span {
                    display: block;
                    margin-top: -40px;
                }
            }
            .line {
                top: 0;
                right: 0;
            }
        }


    }
    .border-shape-wrapper {
        z-index:10;
        position: absolute;
        width: 175px;
        height: 175px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .chevron {
            position: absolute;
            z-index:4;
            //border: 1px solid red;
            width: 22px;
            height: 22px;
            $distance-to-shape: -20px;
            opacity: 0;
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                z-index:2;
                width: 15px;
                height: 15px;
                border-top: 1px solid $white;
                border-right: 1px solid $white;
                //transform: rotate(-45deg);
            }
            &.top {
                left: 50%;
                top: $distance-to-shape;
                transform: translateX(-50%);
                &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
            &.bottom {
                left: 50%;
                bottom: $distance-to-shape;
                transform: translateX(-50%);
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }
            &.right {
                right: $distance-to-shape;
                top: 50%;
                transform: translateY(-50%);
                &::after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
            &.left {
                left: $distance-to-shape;
                top: 50%;
                transform: translateY(-50%);
                &::after {
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
            }
        }
    }
    .border-shape {
        //background: rgba(255,255,255,.1);
        background: rgb(255,255,255);
        background: -moz-radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,.2) 100%);
        background: -webkit-radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,.2) 100%);
        background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,.2) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        width: 10px;
        height: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        border-radius: 2px;
        border: 1px solid #fff;
        z-index: 400;
        opacity: 0;

    }
    #tl-control {
        width: 70%;
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom:20px;
        z-index:400;
        li {

            a {

                color:$white;
                font-size: 13px;
            }
        }
    }
}
