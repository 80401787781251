@import "../../../styles/_variables.scss";
.bg-animation--squaretest {
    padding-top: 0;
    padding-bottom: 300px;
    background: transparent;
    height: 100vh;
    background: $primary
}
.squaretest {
    width: 200px;
    height: 200px;
    background: rgba(0,0,0,.5);
    border: 14px solid rgba(255,255,255,.5);
    border-radius: 4px;
    position: relative;
    top: 200px;
    left:200px;
}